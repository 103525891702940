import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ApiLogState } from './apiLogs.state';

export const API_LOGS_STATE_NAME = 'apiLogs';
const selectApiLogsState =
  createFeatureSelector<ApiLogState>(API_LOGS_STATE_NAME);

const selectApiLogs = createSelector(selectApiLogsState, ({ data }) => data);
const selectDropper = createSelector(
  selectApiLogsState,
  state => state.dropper
);

export default {
  selectApiLogsState,
  selectApiLogs,
  selectDropper,
};
