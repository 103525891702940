import { ApiLog, ApiLogPageModel } from '@models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const apiLogAction = createActionGroup({
  source: 'apiLog',
  events: {
    'fetch ': emptyProps(),
    'fetch success': props<{ data: ApiLog[] }>(),
    'export to csv': props<{ filter: ApiLogPageModel.Filter }>(),
    'fetch dropper': emptyProps(),
    'fetch dropper success': props<{
      data: {
        category: string[];
        type: string[];
      };
    }>(),
  },
});
